import React from "react"
import { Link } from "gatsby"
import styled, { css } from "react-emotion"
import { MdLaunch } from "react-icons/md"

import { scale, rhythm, options } from "../utils/typography"
import { mq, elevation, gutter, colors, animation } from "../utils/presets"

export const linkStyle = css`
  font-family: ${options.headerFontFamily.join(`,`)};
  font-weight: 700;
  letter-spacing: 0.005em;
  position: relative;
  transition: ${animation.speedDefault} ${animation.curveExpo};
  white-space: nowrap;
`

const headerStyle = css`
  
  ${mq.tablet} {
    z-index: ${elevation.overlay + 1};
    position: fixed;
    top:0;
    padding-top: 2rem;
    background: #ffffff;
    width: 100%;
  }
`

const activeStyle = css`
  color: rgba(0, 0, 0, 1);
  &:before {
    background-color: #000;
    bottom: -0.625em;
    content: "";
    height: 0.125em;
    left: 0;
    position: absolute;
    right: 0;
    width: 0.65em;
  }
`;

const SiteTitle = styled(`h1`)`
  margin: 0;
  position: fixed;
  left: 1rem;
  top: 8rem;
  font-size: ${scale(1).fontSize};
  line-height: 1;

  ${mq.tablet} {
    z-index: ${elevation.overlay + 1};
  }
`

const Nav = styled(`nav`)`
  background: #fff;
  overflow-x: auto;
  padding-bottom: ${gutter.default};
  padding-left: ${gutter.default};
  padding-top: ${gutter.default};
  position: fixed;
  width: 100%;
  z-index: 21;

  ${mq.tablet} {
    margin-left: 0;
    margin-right: 0;
    overflow-x: initial;
    padding-left: 0;
    padding-top: 0;
  }
`

const NavList = styled(`ul`)`
  margin: 0;
  list-style: none;
  white-space: nowrap;

  ${mq.tablet} {
    max-width: 30rem;
    white-space: initial;
  }
`

const NavListItem = styled(`li`)`
  display: inline;
`

const NavItem = ({ title, to }) => (
  <NavListItem
    css={`
      &:after {
        color: colors.gatsby;
        content: " | ";
        font-weight: 300;
        opacity: 0.5;
        position: relative;
        padding: 0 ${rhythm(options.blockMarginBottom / 4)};
      }

      &:last-child:after {
        display: none;
      }
    `}
  >
    <Link
      css={linkStyle}
      activeClassName={activeStyle}
      to={to}>
      {title}
    </Link>
  </NavListItem>
)

const Navigation = () => (
  <header css={`${headerStyle}`}>
    <Nav aria-label="Primary Navigation">
      <NavList>
        <NavItem to="/" title="About" />
        <NavItem to="/workshop/" title="Workshop" />
        <NavItem to="/bios/" title="Bios" />
      </NavList>
    </Nav>
  </header>
)

export default Navigation
