import React from "react";
import Img from "gatsby-image";
import { Link, StaticQuery, graphql } from "gatsby";
import styled, { css } from "react-emotion";
import SEO from './seo';
import Navigation, { linkStyle } from "./navigation";
import { rhythm, scale } from "../utils/typography";
import { mq, elevation, offset, offsetXxl, gutter } from "../utils/presets";

const Container = styled(`div`)`
  background: #fff;
  position: relative;

  ${mq.tablet} {
    background: #fff;
    margin-left: ${offset};
    margin-top: 0;
    max-width: 37.5rem;
    padding: ${gutter.tablet};
    position: relative;
  }

  ${mq.desktop} {
    padding: ${gutter.desktop};
    padding-top: ${gutter.tablet};
  }

  ${mq.xxl} {
    margin-left: ${offsetXxl};
  }
`

const Image = styled(Img)`
  bottom: 200px;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;

  ${mq.tablet} {
    bottom: 0;
    left: 0;
    right: auto;
    top: 0;
    width: ${offset};
    height: auto;
  }

  ${mq.xxl} {
    width: ${offsetXxl};
  }
`

const Main = styled(`main`)`
  background: #fff;
  position: relative;
  padding: ${gutter.default};
  top: 200px;

  z-index: ${elevation.overlay};
  ${mq.tablet} {
    top: auto;
    margin-top: 3rem;
    padding-left: 0;
    padding-right: 0;
  }
`

const Logo = styled(`img`)`
  display: inline-block;
  height: 100%;
  margin: 0;
  vertical-align: middle;
  width: 100%;
`

const hideMobile = css`
  display: none;
  ${mq.tablet} {
    display: block;
  }
`

const showMobile = css`
  ${mq.tablet} {
    display: none;
  }
`
const SiteTitle = styled(`h1`)`
  margin: 0;
  position: fixed;
  font-weight: 400;
  left: 1rem;
  top: 8rem;
  font-size: ${scale(1).fontSize};
  line-height: 1;
  z-index: 1;
  a:hover {
    color: white;
  }

  ${mq.tablet} {
    z-index: ${elevation.overlay + 1};
  }
`

class Title extends React.Component {
  render() {
    return (
      <SiteTitle>
        <Link
          to="/"
          css={`
            ${linkStyle};
            color: #ffffff;
          `}
        >
          {this.props.title}
        </Link>
      </SiteTitle>
    )
  }
}

const Layout = ({ pageContext, children, coverImage, fixedImage, imageTitle, imageBackgroundColor, title }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        coverImage: file(
          relativePath: { regex: "/sixtyfour_b.jpg/" }
        ) {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 80
              
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {

      const title = pageContext && pageContext.frontmatter && pageContext.frontmatter.title;
      
      return (
        <>
          <SEO title={title || undefined} />
          <Container>
            <Title title={data.site.siteMetadata.title}></Title>
            {/* <SiteTitle>
              <Link
                to="/"
                css={`
                  ${linkStyle};
                  color: #ffffff;
                `}
              >
                <h1 className="test-class">{data.site.siteMetadata.title}</h1>
              </Link>
            </SiteTitle> */}
            {data.coverImage && (
              <Image
                fluid={data.coverImage.childImageSharp.fluid}
                style={{ position: `fixed` }}
                backgroundColor={imageBackgroundColor ? imageBackgroundColor : false}
                title={imageTitle}
              />
            )}
            <Navigation />
            <Main>
              {children}
            </Main>
          </Container>
        </>
      )
    }}
  />
)

export default Layout
